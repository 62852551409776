<template>
    <ui-create
        name="kits"
        :title="title"
        :load="load"
    >
        <template #body >
            <v-form ref="form" >
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Descrição"
                            v-model="form.descricao"
                            counter
                            maxlength="62"
                            :rules="[
                                form_descricao_rules.required, form_descricao_rules.counter,
                                    form_descricao_rules.min
                            ]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-autocomplete
                            label="Produto"
                            v-model="form.id_produto"
                            :items="sections"
                            item-text="descricao"
                            item-value="id"
                            :rules="[form_produto_rules.required]"
                        ></v-autocomplete>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Quantidade permitida"
                            v-model="form.quantidade_permitida"
                            maxlength="3"
                            v-mask="'###'"
                            :rules="[form_quantidade_rules.required, form_quantidade_rules.nan]"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-checkbox
                            label="Ativo?"
                            v-model="form.ativo"
                            true-value="s"
                            false-value="n"
                        ></v-checkbox>

                        <v-checkbox
                            label="Unico?"
                            v-model="form.unico"
                            true-value="s"
                            false-value="n"
                        ></v-checkbox>

                        <v-checkbox
                            label="Tipo do Rodizio?"
                            v-model="form.tipo_kit"
                            true-value="s"
                            false-value="n"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwKitsCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                id_produto: null,
                quantidade_permitida: '',
                ativo: null,
                unico: null,
                tipo_kit: null
            },

            sections: [],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_produto_rules: {
                required: value => !!value || 'Selecione um produto.'
            },

            form_quantidade_rules: {
                required: value => !!value || 'Quantidade é obrigatória.',
                nan: value => !isNaN(value) || 'Dados incorreto!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Rodizio: ${this.id}` : 'Cadastrar Rodizio'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            // if(this.$refs.form.validate()){
                this.load = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            // }
        },

        update: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/kits/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.clearForm()
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o rodizio!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        create: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/kits/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Rodizio cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o rodizio!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        getData: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/kits/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.load = false
                })
        },

        getSections: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/produtos', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sections = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.getSections()

        this.$root.$on('save-changes-on-kits', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>